import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import { getFirestoreId } from "../../lib/helpers";
import {
    getLoanOfficerName,
    mapGivingToHeroesData,
} from "../../components/GivingToHeroesDetail/lib/helpers";
import GivingToHeroesDetail from "../../components/GivingToHeroesDetail";

const GivingToHeroesHome = ({ data }) => {
    let branches = data.branches.edges.map(e => {
        let g2hCalculatorWidget = e.node.modules.find(widget => {
            return widget.type === "g2hCalculator";
        });

        return {
            slug: e.node.slug,
            name: e.node.name,
            email: e.node.email,
            loanOfficerName: getLoanOfficerName(e.node),
            realtorName: e.node.realtor.name ? e.node.realtor.name : "",
            realtorTitle: e.node.realtor.title ? e.node.realtor.title : "",
            lenderCreditPercentage: get(
                g2hCalculatorWidget,
                "lenderCreditPercentage",
                0.0025,
            ),
            realtorCommissionPercentage: get(
                g2hCalculatorWidget,
                "realtorCommissionPercentage",
                0.025,
            ),
            realtorCreditPercentage: get(
                g2hCalculatorWidget,
                "realtorCreditPercentage",
                0.1,
            ),
        };
    });
    data.pageData.firestoreId = getFirestoreId("giving-to-heroes", "base-page");
    let mappedData = mapGivingToHeroesData(data.pageData);

    return (
        <GivingToHeroesDetail
            branches={branches}
            blogs={data.blogs}
            corporateBlogs={data.news}
            {...mappedData}
        />
    );
};

export default GivingToHeroesHome;

export const pageQuery = graphql`
    query getG2HBasePage {
        branches: allG2HPage(
            filter: { hidden: { eq: false } }
            sort: { fields: name }
        ) {
            edges {
                node {
                    slug
                    name
                    email
                    realtor {
                        name
                        title
                    }
                    loanOfficer {
                        profile {
                            name
                        }
                    }
                    team {
                        profile {
                            name
                        }
                    }
                    modules {
                        type
                        lenderCreditPercentage
                        realtorCommissionPercentage
                        realtorCreditPercentage
                    }
                }
            }
        }
        pageData: g2HPage(
            slug: { eq: "/giving-to-heroes/giving-to-heroes-base-page/" }
        ) {
            id
            headBeginScript {
                name
                src
                async
                html
            }
            headEndScript {
                name
                src
                async
                html
            }
            bodyBeginScript {
                name
                src
                async
                html
            }
            bodyEndScript {
                name
                src
                async
                html
            }
            title
            name
            email
            slug
            cobrandUrl
            loanOfficer {
                slug
                hidden
                gallery {
                    photo
                    image1
                    image2
                    image3
                }
                profile {
                    name
                    email
                    jobTitle
                    licenseIds {
                        license
                        id
                    }
                    branch {
                        slug
                        title
                        address1
                        address2
                        address3
                        city
                        state
                        zip
                        lat
                        lon
                        loanOfficers {
                            slug
                            hidden
                            gallery {
                                photo
                            }
                            profile {
                                name
                                email
                                jobTitle
                                licenseIds {
                                    license
                                    id
                                }
                            }
                        }
                        teams {
                            slug
                            title
                            hidden
                            contact {
                                phone
                                instagram
                                linkedin
                                fax
                                mobilePhone
                                twitter
                                youtube
                                facebook
                            }
                            applyNow
                            profile {
                                name
                                email
                                licenseIds {
                                    license
                                    id
                                }
                            }
                        }
                        staff {
                            title
                            name
                            email
                            photo
                            license
                        }
                        managers {
                            title
                            name
                            email
                            photo
                            license
                        }
                    }
                }
                contact {
                    phone
                    mobilePhone
                    fax
                    facebook
                    instagram
                    linkedin
                    twitter
                    youtube
                }
            }
            team {
                slug
                urlslug
                profile {
                    name
                    email
                    graphic
                    logo
                    licenseIds {
                        license
                        id
                    }
                    branch {
                        slug
                        title
                        address1
                        address2
                        address3
                        city
                        state
                        zip
                        lat
                        lon
                    }
                }
                contact {
                    phone
                    mobilePhone
                    fax
                    facebook
                    instagram
                    linkedin
                    twitter
                    youtube
                }
                loanOfficers {
                    slug
                    hidden
                    gallery {
                        photo
                    }
                    profile {
                        name
                        jobTitle
                        email
                        licenseIds {
                            license
                            id
                        }
                    }
                }
                staff {
                    title
                    name
                    email
                    photo
                    license
                }
                managers {
                    title
                    name
                    email
                    photo
                    license
                }
            }
            realtor {
                name
                title
                title2
                licenseNumber
                phone
                fax
                email
                address
                city
                state
                zip
                url
                photoUrl
                logoUrl
                isDemoRealtor
            }
            modules {
                type
                display
                videos {
                    videoTitle
                    videoCaption
                    videoUrl
                }
                facebookPageUrl
                calendar
                backgroundImage
                backgroundImageCustom
                darkTitleText
                instagramToken
                resourceIntro
                resourceList {
                    resourceFile
                    resourceUrl
                    resourceDescription
                }
                event1 {
                    eventImage
                    eventCustomImage
                    eventName
                    eventDate
                    eventTime
                    eventLocation
                    eventDescription
                    eventUrl
                    eventCta
                }
                event2 {
                    eventImage
                    eventCustomImage
                    eventName
                    eventDate
                    eventTime
                    eventLocation
                    eventDescription
                    eventUrl
                    eventCta
                }
                event3 {
                    eventImage
                    eventCustomImage
                    eventName
                    eventDate
                    eventTime
                    eventLocation
                    eventDescription
                    eventUrl
                    eventCta
                }
                awards {
                    awardDescription
                    awardYear
                    awardImage
                    awardBadge
                }
                social_id
                social_page
                showCalculatorMortgage
                showCalculatorRentVsBuy
                showCalculatorHomeAffordability
                showCalculatorRequiredIncome
                showCalculatorRefinance
                contentImage
                contentTitle
                contentText
                profileShowCompactView
                profileBody
                contactFormFields {
                    type
                    required
                    customFieldType
                    label
                    bammName
                    options {
                        hasCustomLabel
                        label
                        value
                    }
                }
                contactFormTitle
                contactUsText
                contactUsTitle
                contactUsBammUrl
                contactUsLeadEmailCcList
                contactUsConfirmEmailText
                contactUsConfirmEmailFrom
                g2hCalculatorFormTitle
                g2hCalculatorText
                g2hCalculatorTitle
                g2hCalculatorBammUrl
                g2hCalculatorFormFields {
                    type
                    required
                    customFieldType
                    label
                    bammName
                    options {
                        hasCustomLabel
                        label
                        value
                    }
                }
                lenderCreditPercentage
                realtorCommissionPercentage
                realtorCreditPercentage
                aboutG2HHtml
                aboutG2HCobrandUrl
                aboutG2HIndustryIcons {
                    type
                    iconUrl
                    label
                }
                aboutBEHtml
                aboutBEImage
                customTestimonials {
                    text
                    name
                    title
                    city
                    state
                }
                id
            }
            blogs {
                id
                html
                slug
                title
                author {
                    slug
                    profile {
                        name
                    }
                    gallery {
                        photo
                    }
                }
                date
                featured
                categories
                image
            }
            news {
                id
                html
                slug
                title
                date
                featured
                categories
                image
            }
            hero {
                heroBackground
                customPhoto
                photoAlignment
                title
                html
                darkText
            }
        }
    }
`;
